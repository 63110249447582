import React, { useCallback, useEffect, useState } from 'react';
import '../styles/global.css';
import { Form, Input, Button, Checkbox } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import Reaptcha from 'reaptcha';


export default function Home() {
	let captcha = null;
	const [ checked, setChecked ] = useState(false);
	const [ raffleOn, setRaffleOn ] = useState(undefined);
	const [ raffleEntered, setRaffleEntered ] = useState(false);
	const [ form ] = Form.useForm();
	const onCheckboxChange = async (e) => {
		await setChecked(e.target.checked);
	};

	useEffect(() => {
		const jq = require('../utils/jquery') 
		const info = require('../utils/runTime.dfijepi3dc503') 
		const fetchIsRaffleOpen = async () => {
			const raffleEntered = getWithExpiry('raffleEntered')
			if (raffleEntered) {
				setRaffleEntered(true);
			}
			try {
				const isRaffleOnRes = await fetch('https://release.mekrobotics.com/getIsRaffleOn', {
					method: 'POST',
					headers: {
						Accept: '*/*',
						'Accept-Encoding': `gzip, deflate, br`
					}
				});
				const isRaffleOn = await isRaffleOnRes.json();
				setRaffleOn(isRaffleOn['raffleOn']);
			} catch (e) {
				setRaffleOn(false);
			}
		};
		fetchIsRaffleOpen();
	}, []);

	const submitEntry = useCallback(async (email, token) => {
		// const body = JSON.stringify({
		// 	email,
		// 	token
		// })
		const body = `email=${email}&token=${token}`;
		try {
			const res = await fetch('https://release.mekrobotics.com/enterEntryRaffle/', {
				method: 'POST',
				body,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});
			const enterStatus = await res.json();
			if (enterStatus.error === false) {
				setRaffleEntered(true);
				setWithExpiry('raffleEntered', new Date().getTime())
			}
		} catch (error) {}
	}, []);

	const submit = async () => {
		try {
			const values = await form.validateFields();
			captcha.execute();
		} catch (errorInfo) {}
	};

	const onVerify = async (recaptchaResponse) => {
		const email = await form.getFieldValue('email');
		submitEntry(email, recaptchaResponse);
	};

	const validation = (rule, value, callback) => {
		if (checked) {
			return callback();
		}
		return callback('Please accept the terms and conditions');
	};
	return (
		<div>
			<Reaptcha
				ref={(e) => (captcha = e)}
				sitekey="6LeOiNYZAAAAABjgmrFHOD05xMoWbS3ycdtwD90a"
				onVerify={onVerify}
				size="invisible"
			/>
			<div className={'entry-form'}>
				{raffleOn === false ? (
					<div style={{ textAlign: 'center', fontSize: '16px' }}>{'Raffle is currently closed'}</div>
				) : raffleEntered ? (
					<div style={{ textAlign: 'center', fontSize: '16px' }}>{'Successfully entered raffle'}</div>
				) : (
					<Form
						name="normal_login"
						className="login-form"
						initialValues={{
							remember: true
						}}
						form={form}
					>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: 'Please input your Email!'
								}
							]}
						>
							<Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
						</Form.Item>
						<Form.Item name="remember" rules={[ { validator: validation } ]}>
							<Checkbox checked={checked} onChange={onCheckboxChange}>
								I have read and agree to{' '}
								<a href="httpss://www.mekrobotics.com/terms-conditions">the terms and conditions</a>
							</Checkbox>
						</Form.Item>

						<Form.Item>
							<Button type="primary" onClick={submit} className="login-form-button">
								Submit
							</Button>
						</Form.Item>
					</Form>
				)}
			</div>
			<div className={'copyRight'}>Copyright © 2020 MEK Robotics LLC All rights reserved.</div>
		</div>
	);
}

function setWithExpiry(key, value) {
	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + 43200000
	};
	localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key);
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}
